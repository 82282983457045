import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Box, Badge, Drawer, IconButton, Typography, styled } from '@mui/material';
import { Notifications as NotificationsIcon, Close as CloseIcon } from '@mui/icons-material';
import { useStore } from 'hooks';

import { useNavigate } from 'react-router-dom';
import { SettingsIcon } from 'assets/icons';

import Dialog from 'components/shared/dialogs/Dialog';
import ButtonsGroup from 'components/shared/ButtonsGroup';

import Item from './Item';
import DetailsModal from './Details';

import palette from 'theme/palette';

const CONDITION_STATE_FILTER_OPTIONS = [
  { name: 'All', value: null },
  { name: 'Active', value: 'active' },
  { name: 'Resolved', value: 'resolved' },
];

const NotificationsMenu = () => {
  const navigate = useNavigate();
  const { notificationsStore } = useStore();

  const [open, setOpen] = useState(false);

  const openNotifications = useCallback(e => {
    setOpen(Boolean(e.currentTarget));
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickSetting = useCallback(() => {
    setOpen(false);
    navigate('/my-account/notification-settings');
  }, [navigate]);

  const handleConditionStateFilterChange = useCallback(
    value => {
      notificationsStore.setSelectedConditionStateFilter(value);
    },
    [notificationsStore],
  );

  const handleMarkAll = useCallback(() => {
    const ids = notificationsStore.unread.map(({ id }) => id);
    notificationsStore.markAsRead(ids);
  }, [notificationsStore]);

  const openModalDetails = useCallback(
    id => {
      notificationsStore.setNotificationId(id);
    },
    [notificationsStore],
  );

  const viewSensorDetails = useCallback(() => {
    navigate(`/quality-management/system-health`);
    notificationsStore.setNotificationId(null);
    setOpen(false);
  }, [navigate, notificationsStore]);

  const MarkAllButton = (
    <Typography
      className="mark_all_button"
      onClick={handleMarkAll}
      sx={{
        padding: 0,
        color: palette.primary.main,
        textTransform: 'none',
        cursor: 'pointer',
        minWidth: '115px',
      }}
    >
      Mark All as Read
    </Typography>
  );

  const RootBox = (
    <StyledBox className="root_box">
      <Box className="header_box">
        <CloseIcon className="clickable" onClick={handleClose} />
        {MarkAllButton}
      </Box>
      <Box className="main_box">
        <Box className="title_box">
          <Typography fontSize={24}>Notifications</Typography>
          <IconButton onClick={handleClickSetting}>
            <SettingsIcon className="settings_icon" />
          </IconButton>
        </Box>
        <ButtonsGroup
          options={CONDITION_STATE_FILTER_OPTIONS}
          onChange={handleConditionStateFilterChange}
          selected={notificationsStore.selectedConditionStateFilter}
          mb={2}
        />
        <Box className="notifications_list">
          {notificationsStore.filtered.length ? (
            notificationsStore.filtered.map((notification, index) => (
              <Item
                key={index}
                notification={notification}
                index={index}
                onItemClick={openModalDetails}
              />
            ))
          ) : (
            <Typography display="flex" justifyContent="center" mt={1}>
              No new notifications
            </Typography>
          )}
        </Box>
      </Box>
    </StyledBox>
  );

  const Mobile = (
    <StyledRootDialog
      open={open}
      title=""
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      mobileHeaderCenter={false}
      mobileHeaderRight={MarkAllButton}
    >
      {RootBox}
    </StyledRootDialog>
  );

  const Desktop = (
    <StyledDrawer anchor="right" open={open} onClose={handleClose}>
      {RootBox}
    </StyledDrawer>
  );

  return (
    <>
      <IconButton
        id="notification_icon"
        edge="end"
        aria-label="notifications"
        className="notificationsIcon"
        onClick={openNotifications}
      >
        <Badge
          color="error"
          invisible={!notificationsStore.unreadCount}
          badgeContent={notificationsStore.unreadCount}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {Mobile}
      {open && Desktop}
      <DetailsModal onViewSensorDataClick={viewSensorDetails} />
    </>
  );
};

export default observer(NotificationsMenu);

const StyledBox = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  width: '320px',
  padding: '16px',

  [breakpoints.down('sm')]: {
    width: '100%',
  },

  '.header_box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  '.main_box': {
    display: 'flex',
    flexDirection: 'column',
  },

  '.title_box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '16px',

    [breakpoints.down('sm')]: {
      marginTop: 0,
    },

    p: {
      color: palette.whiteEmphasis.high,
    },
  },

  '.clickable_icon': {
    cursor: 'pointer',
  },

  '.mark_all_button': {
    padding: 0,
    color: palette.primary.main,
    textTransform: 'none',
    cursor: 'pointer',
  },

  '.settings_icon': {
    color: palette.whiteEmphasis.high,
  },

  '.notifications_list': {
    marginTop: '16px',
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
  },
}));

const StyledDrawer = styled(Drawer)(({ theme: { palette, breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledRootDialog = styled(Dialog)(({ theme: { palette, breakpoints } }) => ({
  [breakpoints.up('sm')]: {
    display: 'none',
  },

  '.titleDark': {
    background: palette.background.main,
  },
}));
