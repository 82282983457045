import React from 'react';
import { observer } from 'mobx-react';

import { Box, Typography, styled } from '@mui/material';
import {
  Close as CloseIcon,
  SchoolOutlined as SchoolOutlinedIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { useStore, useMediaQueries } from 'hooks';

import Dialog from 'components/shared/dialogs/Dialog';
import AlertBox from 'components/shared/AlertBox';

const DetailsModal = ({ onViewSensorDataClick }) => {
  const { notificationsStore } = useStore();
  const { isTabletOrGreater } = useMediaQueries();

  const notification = notificationsStore.selectedNotification;

  const onClose = () => {
    notificationsStore.setNotificationId(null);
  };

  if (!notification) return null;

  const { alarm, _alarms_alert_caption } = notification;

  return (
    <StyledDialog open={Boolean(notification)} onClose={onClose} hideTitle={isTabletOrGreater}>
      <Box className="header_box">
        <WarningIcon className="alert_icon_mobile mobile_only" />

        <Box className="title_box">
          <Typography className="caption">{_alarms_alert_caption}</Typography>
          <CloseIcon className="close_icon desktop_only" onClick={onClose} />
        </Box>
        <Typography className="title">
          We detected a temperature that exceeded your settings threshold for your{' '}
          {alarm?.metadata?.original_sensor_name || alarm?.operand_id} sensor.
        </Typography>
      </Box>
      <Box className="content_box">
        <AlertBox
          isAbsolute
          className="alert_box"
          notification={notification}
          onViewSensorDataClick={onViewSensorDataClick}
        />
        <Box className="description_box">
          <Box className="row_box">
            <SchoolOutlinedIcon />
            <Box className="text_box">
              <Typography className="title">{alarm.troubleshooting.educational_details}</Typography>
            </Box>
          </Box>
          {alarm.troubleshooting.steps.map((text, index) => (
            <Box className="row_box" key={`step-${index}`}>
              <Typography component={'div'} className="number">
                {index + 1}{' '}
              </Typography>
              <Box className="text_box">
                <Typography className="title">{text}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </StyledDialog>
  );
};

export default observer(DetailsModal);

const StyledDialog = styled(Dialog)(({ theme: { palette, breakpoints } }) => ({
  width: '1130px',
  height: '410px',
  position: 'relative',
  padding: '20px',
  backgroundColor: palette.elevation.overlay1,
  borderRadius: '8px',

  '.MuiDialogTitle-root': {
    backgroundColor: palette.background.main,
  },

  [breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    padding: '0 16px',
    textAlign: 'center',
  },

  '.header_box': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'center',
    gap: '16px',
    borderBottom: '1px solid #313131',
    paddingBottom: '16px',

    [breakpoints.down('sm')]: {
      gap: '8px',
      alignItems: 'center',
      border: 'none',
      paddingBottom: 0,
    },
  },

  '.content_box': {
    display: 'flex',
    gap: '40px',
    paddingTop: '16px',

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 0,
      gap: '8px',
    },

    '.description_box': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',

      '.row_box': {
        display: 'flex',

        svg: {
          marginRight: '8px',
          color: palette.whiteEmphasis.high,
        },
      },

      '.number': {
        padding: '10px',
        color: palette.primary.main,
        backgroundColor: '#2C1F13',
        height: '24px',
        width: '24px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginRight: '8px',
      },
    },
  },

  '.title_box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '.second_row': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '.caption': {
    fontSize: '20px',
    color: palette.status.red,
  },

  '.title': {
    fontSize: '14px',
    color: palette.whiteEmphasis.high,
  },

  '.hidden': {
    display: 'none',
  },

  '.alert_icon_mobile': {
    color: palette.status.red,
    width: '48px',
    height: '48px',

    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  '.desktop_only': {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  '.mobile_only': {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  '.close_icon': {
    color: palette.whiteEmphasis.high,
    cursor: 'pointer',
  },
}));
