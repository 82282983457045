import { types } from 'mobx-state-tree';

const ConditionContext = types.model({
  cable_color: types.maybeNull(types.string),
  cable_length_m: types.maybeNull(types.number),
  caption: types.maybeNull(types.string),
  establishment_id: types.maybeNull(types.integer),
  field_registered_at: types.maybeNull(types.string),
  field_registration_age_seconds: types.maybeNull(types.number),
  gateway_id: types.maybeNull(types.integer),
  gateway_type_id: types.maybeNull(types.integer),
  id: types.maybeNull(types.integer),
  latest_sample_received_at: types.maybeNull(types.string),
  missing: types.maybeNull(types.boolean),
  reported_as: types.maybeNull(types.string),
  temp_c: types.maybeNull(types.number),
  temperature_age_seconds: types.maybeNull(types.number),
  temperature_missing_seconds: types.maybeNull(types.number),
});

export const Notification = types.model({
  id: types.integer,
  condition_id: types.maybeNull(types.integer),
  role_user_id: types.maybeNull(types.integer),
  status_code: types.maybeNull(types.integer),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  archived: types.optional(types.boolean, false, [undefined, '', null]),
  _alarms_alert_id: types.maybeNull(types.integer),
  _alarms_alert_caption: types.maybeNull(types.string),
  _conditions_happened_from: types.maybeNull(types.string),
  _conditions_latest_trigger_at: types.maybeNull(types.string),
  _gateways_id: types.maybeNull(types.integer),
  _gateways_name: types.maybeNull(types.string),
  _gateways_cooler_id: types.maybeNull(types.integer),
  _coolers_name: types.maybeNull(types.string),
  _conditions__closed: types.optional(types.boolean, false, [undefined, '', null]),
  _conditions_alarm_id: types.maybeNull(types.integer),
  _conditions_criteria: types.maybeNull(types.string),
  _conditions_happened_to: types.maybeNull(types.string),
  _conditions_context: types.maybeNull(ConditionContext),
});

export const UserDevice = types.model({
  archived: types.boolean,
  created_at: types.string,
  created_by: types.integer,
  device_class: types.string,
  device_name: types.string,
  id: types.integer,
  device_fingerprint: types.maybeNull(types.union(types.string, types.number)),
  notifications_muted: types.boolean,
  operating_system: types.string,
  user_id: types.integer,
});
