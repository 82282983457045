import React from 'react';
import clsx from 'clsx';
import { Box, Typography, Button, styled } from '@mui/material';

import { ArrowForward, ReportProblemOutlined } from '@mui/icons-material';

import AlertSlider from 'components/shared/AlertSlider';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { dateUtilities, numberUtilities } from 'utils';
import { useStore } from 'hooks';

const AlertBox = ({
  notification = null,
  isAbsolute = false,
  onViewSensorDataClick,
  condition = null,
}) => {
  const { deviceHistoryStore, notificationsStore } = useStore();

  const isNotification = !!notification;
  const gateway = deviceHistoryStore.sortedGateways.find(
    g => g.id === condition?.context.gateway_id,
  );
  const fallbackAlarm = notificationsStore.alarms.find(a => a.id === condition?.alarm_id);
  const minMax = notificationsStore.getMinMax(condition?._alarms_operand_id);
  const countedCriteriaValue = numberUtilities.celsiusWrapper(condition?.context?.temp_c, {
    signsAmount: 1,
  });
  const data = {
    alertCaption: isNotification
      ? notification._alarms_alert_caption
      : condition.message.alert_caption,
    coolerName: isNotification ? notification._coolers_name : gateway?._coolers_name,
    happened_from: isNotification
      ? notification._conditions_happened_from
      : condition.happened_from,
    latest_trigger_at: isNotification
      ? notification._conditions_latest_trigger_at
      : condition.latest_trigger_at,
    gateways_name: isNotification ? notification._gateways_name : gateway?.name,
    original_sensor_name: isNotification
      ? notification.alarm?.metadata?.original_sensor_name
      : fallbackAlarm?.metadata?.original_sensor_name,
    conditionData: isNotification ? notification.condition : condition,
    criteriaValue: isNotification ? notification.criteriaValue : countedCriteriaValue,
    min: isNotification ? notification.min : minMax.min,
    max: isNotification ? notification.max : minMax.max,
  };

  const fetchSensorData = () => {
    const gatewayId = isNotification ? notification._gateways_id : condition.context.gateway_id;
    deviceHistoryStore.setSelectedGatewayId(gatewayId);
    const { from, to } = dateUtilities.getDatesByPeriod('custom', {
      fromDate: data.happened_from || data.latest_trigger_at,
      toDate: data.latest_trigger_at || data.happened_from,
    });
    deviceHistoryStore.setPeriod({
      from,
      to,
    });
    onViewSensorDataClick();
  };

  return (
    <StyledRootBox>
      <Box
        className={clsx('alert_wrapper', {
          active: !data.conditionData?._closed,
          resolved: data.conditionData?._closed,
        })}
      >
        <Box className="wrapper_main_box">
          <Box className="icon_wrapper">
            {!data.conditionData?._closed ? (
              <ReportProblemIcon className="alert_icon active" />
            ) : (
              <ReportProblemOutlined className="alert_icon resolved" />
            )}
          </Box>

          <Box className="main_box">
            <Box className="info_box">
              <Box className="title_box desktop_only">
                <Typography variant="title desktop_only">{data.alertCaption + ' Alert'}</Typography>
                <Typography
                  className={clsx('status', {
                    active: !data.conditionData?._closed,
                    resolved: data.conditionData?._closed,
                  })}
                >
                  {data.conditionData?._closed ? 'Resolved' : 'Active'}
                </Typography>
              </Box>
              <Typography variant="body2" className="desktop_only">
                {data.coolerName} - {data.original_sensor_name}
              </Typography>
              <Typography variant="body2">
                Issue Started -{' '}
                <span className="white_text">
                  {dateUtilities.formatDateIncludingToday(data.happened_from)}
                </span>
              </Typography>
              <Typography variant="body2">
                {data.conditionData?._closed ? 'Resolved On' : 'Latest Trigger'}
                {' - '}
                <span className="white_text">
                  {dateUtilities.formatDateIncludingToday(
                    data.conditionData?._closed
                      ? data.conditionData?.happened_to
                      : data.latest_trigger_at,
                  )}
                </span>
              </Typography>
            </Box>
            <Box className="slider_box">
              <AlertSlider
                isAbsolute={isAbsolute}
                value={data.criteriaValue}
                min={data.min}
                max={data.max}
              />
            </Box>

            <Box className="mobile_only">
              <Typography variant="title">{data.coolerName}</Typography>
              <Typography variant="body2">{data.gateways_name}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className="action_box">
          <Button className="view_button" endIcon={<ArrowForward />} onClick={fetchSensorData}>
            View sensor data
          </Button>
        </Box>
      </Box>
    </StyledRootBox>
  );
};

export default AlertBox;

const StyledRootBox = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  borderRadius: '16px',
  [breakpoints.up('sm')]: {
    background: palette.elevation.overlay3,
  },
  '.icon_wrapper': {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  '.alert_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '401px',
    height: '272px',
    borderRadius: '16px',
    padding: '24px',
    justifyContent: 'space-between',
    '&.active': {
      border: `1px solid ${palette.status.red}`,
    },
    [breakpoints.down('sm')]: {
      all: 'unset',
      '&.active': {
        border: 'none',
      },
    },
  },

  [breakpoints.down('sm')]: {
    border: 'none',
    padding: '16px',
  },

  '.alert_icon': {
    color: palette.whiteEmphasis.high,
    width: '24px',
    height: '24px',
    marginRight: '14px',
    marginTop: '4px',
    '&.active': {
      color: palette.status.red,
    },
    '&.resolved': {
      color: palette.whiteEmphasis.medium,
    },
    [breakpoints.down('sm')]: {
      width: '48px',
      height: '48px',
    },
  },

  '.wrapper_main_box': {
    display: 'flex',
  },

  '.main_box': {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    width: '100%',

    [breakpoints.down('sm')]: {
      gap: '12px',
      alignItems: 'center',
    },

    '.info_box': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',

      [breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },

    '.slider_box': {
      width: '100%',

      [breakpoints.down('sm')]: {
        marginTop: '16px',
      },
    },
  },

  '.status': {
    borderRadius: '36px',
    fontSize: '12px',
    padding: '2px 4px',

    '&.active': {
      color: palette.status.red,
      border: `1px solid ${palette.status.red}`,
    },

    '&.resolved': {
      color: palette.status.green,
      border: `1px solid ${palette.status.green}`,
    },

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  '.action_box': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '12px',
  },

  '.view_button': {
    borderRadius: '4px',
    textTransform: 'none',
    display: 'flex',
    margin: '0 auto',
    padding: '12px 14px 12px 24px',
    color: '#ffffff99',
    border: `1px solid ${palette.primary.main}`,
    svg: {
      fontSize: '17px',
      marginBottom: '2px',
    },
  },

  '.white_text': {
    color: palette.whiteEmphasis.high,
  },

  '.desktop_only': {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  '.mobile_only': {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  '.title_box': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
