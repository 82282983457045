import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { Paper, Button, styled } from '@mui/material';
import { useEffect } from 'react';

const ButtonsGroup = ({ options = [], onChange, selected }) => {
  const [activeOptions, setActiveOptions] = useState(selected);

  useEffect(() => {
    setActiveOptions(selected);
  }, [selected]);

  const handleChangeOptions = useCallback(
    index => {
      setActiveOptions(index);
      if (typeof onChange === 'function') {
        onChange(index);
      }
    },
    [onChange],
  );

  if (options.length === 0) {
    return null;
  }

  return (
    <StyledPaper elevation={0}>
      {options.map(({ name, value }) => (
        <Button
          key={value}
          className={clsx('period_button', { active: value === activeOptions })}
          onClick={() => handleChangeOptions(value)}
        >
          {name}
        </Button>
      ))}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)(({ theme: { palette } }) => ({
  borderRadius: '8px',
  padding: '2px',
  height: 'fit-content',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: palette.background.main,

  '.period_button': {
    borderRadius: '8px',
    height: '24px',
    textTransform: 'none',
    fontSize: '10px',
    color: palette.whiteEmphasis.medium,
    minWidth: '70px',
    width: '100%',

    '&.active': {
      backgroundColor: palette.background.overlay8,
      color: palette.whiteEmphasis.high,
    },

    '&:not(:last-of-type) ': {
      marginRight: '2px',
    },
  },
}));

export default ButtonsGroup;
