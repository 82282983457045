import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { Box, Typography, styled } from '@mui/material';
import { WarningAmberOutlined, Warning } from '@mui/icons-material';

import palette from 'theme/palette';
import { calcPower, calcNoise, calcAccuracy } from 'context/calibration';
import { dateUtilities } from 'utils';

import SensorGauge from 'components/@SystemConfiguration/HardwareManagement/SensorGauge';

const calibrationStatusMap = {
  0: 'Not Calibrated',
  1: 'Calibrated with Issues',
  2: 'Calibrated',
  3: 'Calibrated - Legacy',
};

const CalibrationTab = ({ sensor }) => {
  if (!sensor) return '';

  const isSensorCalibrated = !!sensor.calibration_details;
  const isPreviousCalibrated = [1, 2].includes(sensor.calibration_status_code);
  const power = calcPower(sensor?.calibration_details?.power);
  const noise = calcNoise(sensor?.calibration_details?.signal);
  const accuracy = calcAccuracy(sensor?.calibration_details?.accuracy_ratio * 100);
  const isFlowVolumeCalibrated = !!sensor?.calibration_details?.accuracy_ratio;
  const outOfRange = isSensorCalibrated && noise.level !== 'Good';

  const calibrationSensorState = (
    <Box className={clsx('graphs', { hidden: !isSensorCalibrated })}>
      <SensorGauge className="power_gauge" type="power" value={power.value} level={power.level} />
      <SensorGauge className="noise_gauge" type="noise" value={noise.value} level={noise.level} />
    </Box>
  );

  const calibrationFlowVolumeState = (
    <Box className={clsx('graphs', { hidden: !isFlowVolumeCalibrated })}>
      <SensorGauge
        className="flow_gauge"
        type="flow"
        value={accuracy.percentage}
        level={accuracy.level}
      />
    </Box>
  );

  return (
    <StyledRootBox>
      <Box className="tab_content">
        <Box className="sensor_calibration_box">
          <Box className="section sensor_calibration">
            <Box className="sensor_heading">
              <span className="sensor_title">
                <Typography className="title" variant="h5">
                  Sensor Calibration
                </Typography>
                {outOfRange && <WarningAmberOutlined htmlColor={palette.status.red} />}
              </span>
            </Box>
            <Box className="section_content">
              <Box className="section_info">
                <Box className="label">
                  <Typography className="label_title" variant="h7">
                    Power
                  </Typography>
                  <Typography
                    variant="h7"
                    color={
                      !isSensorCalibrated
                        ? palette.whiteEmphasis.medium
                        : power.level === 'Good'
                        ? palette.status.green
                        : palette.status.red
                    }
                  >
                    {isPreviousCalibrated ? power.level : 'N/A'}
                  </Typography>
                </Box>
                <Box className="label noise_label">
                  <Typography className="label_title" variant="h7">
                    Noise
                  </Typography>
                  <Typography
                    variant="h7"
                    color={
                      !isSensorCalibrated
                        ? palette.whiteEmphasis.medium
                        : noise.level === 'Good'
                        ? palette.status.green
                        : palette.status.red
                    }
                  >
                    {isPreviousCalibrated ? noise.level : 'N/A'}
                  </Typography>
                </Box>
              </Box>
              {calibrationSensorState}
            </Box>
          </Box>
          <Box className={clsx('outOfRange', { hidden: !outOfRange })}>
            <Box className="warning_icon">
              <Warning fontSize="small" htmlColor={palette.status.red} />
            </Box>
            <Box className="out_of_range_text">
              <Typography variant="caption" color={palette.status.red}>
                Flow Volume calibration may be affected because the sensor values are outside the
                recommended ranges
              </Typography>
            </Box>
          </Box>

          <Box className={clsx('section flow_volume', { disabled: !isSensorCalibrated })}>
            <Box className="sensor_heading">
              <span className="sensor_title">
                <Typography className="title" variant="h5">
                  Flow Volume
                </Typography>
              </span>
            </Box>

            <Box className="section_content">
              <Box className="section_info">
                <Box className="label">
                  <Typography className="label_title" variant="h7">
                    Accuracy
                  </Typography>
                  <Typography
                    variant="h7"
                    color={
                      accuracy.level === 'Good'
                        ? palette.status.green
                        : !isFlowVolumeCalibrated
                        ? palette.whiteEmphasis.medium
                        : palette.status.red
                    }
                  >
                    {isPreviousCalibrated ? accuracy.level : 'N/A'}
                  </Typography>
                </Box>
              </Box>
              {calibrationFlowVolumeState}
            </Box>
          </Box>
        </Box>
        <Box className="calibration_details_box">
          <Typography className="title">Calibration Details</Typography>
          <Box className="info_point_box">
            <Typography className="info_point_title">Calibration Status</Typography>
            <Typography className="info_point_value">
              {calibrationStatusMap[sensor.calibration_status_code] || 'N/A'}
            </Typography>
          </Box>
          <Box className="info_point_box">
            <Typography className="info_point_title">Date Calibrated</Typography>
            <Typography className="info_point_value">
              {isPreviousCalibrated
                ? dateUtilities.formatDateIncludingToday(sensor.latest_calibration_at, {
                    fallback: 'N/A',
                  })
                : 'N/A'}
            </Typography>
          </Box>
          <Box className="info_point_box">
            <Typography className="info_point_title">Calibrated by</Typography>
            <Typography className="info_point_value">
              {sensor._users_display_name_latest_calibration_by || 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledRootBox>
  );
};

export default observer(CalibrationTab);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  padding: '16px',
  backgroundColor: palette.background.main,
  height: '518px',
  display: 'flex',

  '.title': {
    fontSize: '20px',
    fontWeight: '500',
    color: palette.whiteEmphasis.high,
  },

  '.tab_content': {
    width: '100%',
    display: 'flex',
    padding: '24px',
    borderRadius: '12px',
    backgroundColor: palette.secondary.greyLight,
  },

  '.sensor_calibration_box': {
    width: '60%',
    padding: '16px',

    '.sensor_heading': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '.sensor_title': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '16px',
    },

    '.section_content': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    '.graphs': {
      margin: `0 32px`,
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
    },

    '.section': {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',

      '&.flow_volume': {
        marginTop: '24px',
      },
      '&_content': {
        alignItems: 'center',
        marginTop: '4px',
        padding: '24px',
        backgroundColor: palette.background.overlay2,
        border: `1px solid ${palette.outline.main}`,
        borderRadius: '8px',
        display: 'flex',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
        '.label': {
          display: 'flex',
          flexDirection: 'column',
          '&.noise_label': {
            marginTop: '24px',
          },
        },
        '.label_title': {
          color: palette.whiteEmphasis.medium,
        },
        '.noise_gauge': {
          marginTop: '32px',
        },
      },

      '&.disabled': {
        '*': {
          opacity: 0.65,
        },
      },
    },
  },

  '.outOfRange': {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',

    '&.hidden': {
      display: 'none',
    },

    '.warning_icon': {
      marginRight: '8px',
    },
  },

  '.calibration_details_box': {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '16px',

    '.info_point_box': {
      fontWeight: '500',

      '.info_point_title': {
        fontSize: '11px',
        color: palette.whiteEmphasis.medium,
      },
      '.info_point_value': {
        fontSize: '14px',
        color: palette.whiteEmphasis.high,
      },
    },
  },
}));
